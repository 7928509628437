.dashboard-links {
    &__tabs {
        margin-bottom: 20px;
        .tabs__tab {
            font-size: 17px;
        }
    }

    &__list {
        & li::before {
            content: '–';
            padding-right: 7px;
        }

        &_empty_yes {
            color: var(--secondary-text);
        }
    }

    &__item {
        white-space: nowrap;
    }

    &__tab-content {
        display: flex;
    }

    &__tab-content-item {
        flex-grow: 1;
        width: 50%;

        &:not(:first-child) {
            padding-left: 50px;
        }
    }

    &__tab-content-item-header {
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: capitalize;
    }
}
