.column-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__label-icon {
        display: flex;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
        align-items: center;

        &_align_center {
            justify-content: center;
        }
        &_align_right {
            justify-content: right;
        }
        &_align_left {
            justify-content: left;
        }

        &_sortable {
            cursor: pointer;
        }
    }

    &__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;

        &_sub-column {
            padding-left: 4px;
        }
    }

    &__tooltip {
        display: inline;
    }

    &__loader {
        width: 60px;
        margin-bottom: -5px;
    }

    &__page-counter {
        color: var(--secondary-text);
        font-weight: normal;
    }
}
