.job-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(680px, 1fr));
    grid-column-gap: 30px;

    .elements-error {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .collapsible-section {
        margin-top: 0;
        margin-bottom: 20px;
        box-shadow: 0 2px 5px var(--default-background);
        border: 1px solid var(--light-divider);
        border-radius: 3px;
        padding: 24px 20px;

        .elements-heading_size_m:last-child {
            margin-bottom: 0;
        }
    }

    .elements-alert {
        margin-bottom: 20px;
    }

    .events {
        &__table {
            &-item {
                &_type {
                    &_phase {
                        width: 270px;
                    }

                    &_progress {
                        width: auto;
                    }
                }
            }
        }
    }
}
