body.g-root {
    --g-color-base-brand: #ffb23e;
    --g-color-text-brand: rgba(0, 0, 0);
    --g-color-text-brand-contrast: rgba(0, 0, 0, 0.85);
    --filter-color: rgba(255, 178, 62, 0.1);

    --g-color-base-brand-hover: #e58a00;

    --g-color-base-selection: rgba(255, 178, 62, 0.2);
    --g-color-base-selection-hover: rgba(255, 178, 62, 0.5);

    &.g-root_theme_dark,
    &.g-root_theme_dark-hc {
        --g-color-base-brand-hover: rgb(229, 138, 0, 0.9);
        --g-color-text-brand: rgba(255, 255, 255);
    }

    &.g-root_theme_light,
    &.g-root_theme_light-hc {
        .login-page__button {
            --g-color-text-brand-contrast: #ffffff;
        }
    }

    & .g-dialog-footer {
        padding: 12px 32px;
    }

    & .g-breadcrumbs__popup {
        z-index: var(--dialog-suggest-z-index) !important;
    }
}
