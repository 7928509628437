.app-navigation {
    &__settings {
        height: 100%;
        width: 600px;
        overflow: auto;
        padding: 1em;
    }

    &__panel {
        display: flex;
        flex-direction: column;
    }

    &__panel-content {
        flex-grow: 1;
        overflow: auto;
        min-width: 250px;
        overflow-x: hidden;
    }

    .gn-split-pane {
        z-index: var(--aside-header-z-index) !important;
    }

    .gn-aside-header-logo__logo-link {
        display: flex;
    }

    .gn-aside-header {
        &__aside {
            position: fixed;
        }

        &__content {
            padding-left: var(--gn-aside-header-size);
            width: 100%;
        }
    }
}

svg.app-navigation {
    &__logo-icon {
        border-radius: 4px;
    }
}
