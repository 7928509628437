.scheduling-pool-tags {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 1;

    overflow: hidden;

    &__tag {
        margin-right: 1ex;
        flex-shrink: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
            margin-right: 0;
        }
    }
}
