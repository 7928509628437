.yt-text {
    &__secondary {
        color: var(--secondary-text);

        &_disabled {
            color: var(--disabled-link);
        }
    }

    &__bold {
        font-weight: var(--yt-font-weight-bold);
    }

    &__warning {
        color: var(--warning-text);
    }

    &__no-wrap {
        white-space: nowrap;
    }

    &__escaped {
        & .unipika {
            display: inline;
            white-space: nowrap;
            font-family: var(--g-font-family-sans);
            color: inherit;

            .quote {
                display: none;
            }

            .escape {
                word-spacing: 5px;
                white-space: pre-wrap;
                background-color: var(--error-background);
            }

            & .string {
                position: relative;
                color: inherit;
                vertical-align: bottom;
            }
        }
    }
}
