@mixin yt-fonts-variables {
    --g-font-family-sans: 'Manrope', 'Helvetica Neue', helvetica, arial, sans-serif;
    --g-font-family-monospace: 'RobotoMono', monospace;
    --yt-font-weight-bold: 700;
    --g-font-size: 14px;
    --g-line-height: 1.428571429;
}

@mixin font-body2 {
    font-size: var(--g-text-body-2-font-size);
    line-height: var(--g-text-body-2-line-height);
}

@mixin font-body3 {
    font-size: var(--g-text-body-3-font-size);
    line-height: var(--g-text-body-3-line-height);
}
