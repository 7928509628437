.job-detail {
    &__content {
        &_loading {
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
