.yc-datepicker-presets {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    color: var(--g-color-text-secondary);
    background-color: var(--g-color-base-generic);

    .yc-datepicker_mobile & {
        justify-content: center;
    }

    &__item {
        margin-left: 16px;
        cursor: pointer;

        .yc-datepicker_desktop &:hover {
            color: var(--g-color-text-complementary);
        }

        .yc-datepicker_mobile &:first-child {
            margin-left: 0;
        }
    }
}
