.support-form {
    &__toggler {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: var(--bug-report-z-index);
        width: 45px;
        height: 32px;
        cursor: pointer;
    }

    &__toggler-moo {
        position: absolute;
        top: 3px;
        left: 7px;

        display: block;

        height: 27px;
        width: 25px;

        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAbCAYAAACJISRoAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAABIAAAASABGyWs+AAAEj0lEQVRIx62WfUxVZRzH7+Xee+47941zzj1vF7i8v8o7k1DAiyQ6hAQJRjgmm82aWWswl384q82Wf+AfzY25EOcq5tpCWQtarj90Oclcy4q1JgvMINrK1cos89vv3D2XXcmC5n22z+7Zec75fp/fy/OcazD8/2H8DxI2lkU1TRlNlMlKkeVrMvk6oQYkuHWlGN17mehOWGpUTQHRGT+paIqZTH4gTA9rFDXjVWWTrCmLRH78AhRVziCT9xNiQiQRJkFRpunXTFgJe1CRX9KjZPMPNXQBO+H38gIqgm70FEp4vFhDSJUgqdFU1lNEO9aS+wfOTYXEI9frldszfcV4piYLWckchiK5OL6tEC/WZ8Hp8ZCJCk1Te8lo6d9qFOugVComeE25YVOV8omAIE5J4m+LrWHgVCtw+SjwyUl0ZEs4WK5hsrMcJ7YXQ3Ra4Of5WV2IjPJIZ4a4QOwiMv4RiagpdS5VebZQkucn5CDmGzRgpAWYHgI+HydOYXRDWK8BBitSMVCbCYclCWle22T8qklc1dueyIzP+X3puqhJmK1V8fu+Stw79zxwdQy4cgKYHMRJxYmNnAmVLg5+lzVqqLm5cfp1EZY4veXFxzpneeKyJr45VxfCT90FuPNqJ+6OHQCmDgNj/fiiIQMT4QCut5XiZiQDg1R83SRdEWatNmslXQdYk+gdaIp1nVmSxLAsS01+USg0+/zhuVIB320O4/tdj+DXoX7cfW037gw04UokC58WqfjjQDP+2t+AuZ0lOF+Xji3ZoSWPwL8dVKRLwaC4hzQdzCS2eINVVeVRveABWZqvSZWWbjWlY2FHBb59ogE32qqw2FKMyTIVZ3IliqAC8+0lWOoowcL2AozkeLFXcr5FOm1Emb5nqA4fxUURTZdT738ilSh+Kuzr/6WjHIu99bjZXoWbj+bjTJ6IdzIEXC1Pw8z6ML6J5ODLSDbeK+YxGLDBazbpq28k8j0+b4miSK3MZLkmbkIgsohqo8X63L1De3Dr0F4sbC3CuRwe41IyprN4XFun4Fp1Oj4sknFaScbhZAu6HNzH9F4LUUqohIedBjGT6NCL5GOR6A9uO9bXhZ9fP4azjbUY9lhxMezHpcIgLpSGME5RHZfdOEgGrU4bjEZjL72znkhjOvYHdJiBYykTiTxik89pH+5zJWGEtwGRTKC7jKjGu/lBvMI7sN9lRrvV/KMnEJjwq/JXeppYNpxMz7wyEhNzTtb3EFFBPObguNHhVB/QnA+0l5NZNj5L9+BJtxWNdu4DeuZpokuiQguisG9FmkwrI4ntFd3IS+jHQC2hH3a7sx3c0Tc25J5/IT1wNk8S/jQ5HEfofg/RTNSkCPwAz6dsZlFYDKt892MnrR52DlFFbCS2EDuJPq8i32ZFriEKiBBLs5tFYTas8ik2sof0FfEsdWnMsMTuckVTQ9friDATT2ZHiY1FYVrNJN7IxgS8zFBOEVJ62MdJZkeHi63eGmeQtBaTmFESM+OYmIe1p4+ZO9icKa6T7tt8ax0xM45F5mDY4wwS8gcvlj4LE+bYdWz1axp/A3Hhd6zNAQGZAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDEyLTA0LTI2VDE3OjEzOjU1KzAzOjAw+ERvTgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxMi0wNC0yNlQxNzoxMzo1NSswMzowMIkZ1/IAAAAASUVORK5CYII=');
    }

    &__toggler-back {
        fill: var(--light-background);
        stroke: var(--light-divider);
    }
}
