.job-specification {
    position: relative;

    &__content {
        &_loading {
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .checkbox {
        margin-right: 16px;
    }
}
