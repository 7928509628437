.tablet-stores {
    &_loading {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .meta-table-item {
        &__key {
            min-width: 130px;
        }
    }
}
