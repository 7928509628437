@mixin setColor($color, $background-color) {
    color: $color;
    background-color: $background-color;
}

.status-block {
    border-radius: 4px;
    font-weight: var(--yt-font-weight-bold);
    padding: 2px 1ex;
    margin: 1px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &__container {
        max-width: 100%;
    }

    &_theme {
        &_warning,
        &_banned {
            @include setColor(var(--warning-text), var(--warning-background));
        }

        &_info {
            @include setColor(var(--info-text), var(--info-background));
        }

        &_success {
            @include setColor(var(--success-text), var(--success-background));
        }

        &_danger,
        &_full,
        &_alerts {
            @include setColor(var(--danger-text), var(--danger-background));
        }

        &_default,
        &_decommissioned {
            @include setColor(var(--default-text), var(--default-background));
        }
    }
}
