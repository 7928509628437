@import '../../../styles/mixins.scss';

.account-quota {
    padding: 16px 0;
    &__value {
        display: flex;
        align-items: center;
    }

    &__name {
        font-weight: var(--yt-font-weight-bold);
        flex: 0 0 150px;

        @include ellipsis();
    }
    &__progress {
        flex-grow: 1.5;
        flex-shrink: 1;
        padding: 0 1em;
    }

    &__progress-tooltip {
        display: block;
    }

    &__progress-tooltip-popup {
        max-width: unset;
    }
}
