.collapsible-section {
    &_margin_top {
        margin-top: 20px;
    }

    &_margin_bottom {
        margin-bottom: 20px;
    }

    &__title {
        white-space: nowrap;
    }

    &__toggler {
        cursor: pointer;
        margin-left: 4px;
        padding-right: 20px;
    }

    .elements {
        &-block {
            margin-bottom: 10px;
        }

        &-heading__heading {
            &_clickable_yes {
                cursor: pointer;
            }
        }
    }
}
