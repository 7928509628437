.tags-filter {
    display: flex;

    &__control {
        flex-grow: 1;
        max-width: 370px;
    }

    &__icon {
        height: unset;
        margin-right: 2px;
    }
}
