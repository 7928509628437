.yt-color-circle {
    display: inline-block;
    width: 1.3ex;
    height: 1.3ex;
    border-radius: 50%;

    &_margin-right {
        margin-right: 0.5ex;
    }
}
