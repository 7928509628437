@import '../../../styles/mixins';

.table-info {
    &__info {
        @include yt-fonts();

        display: flex;
        align-items: center;
    }

    &__showing,
    &__total {
        margin: 0;
    }

    &__info-text {
        margin-right: 8px;
        margin-left: 16px;
        color: var(--primary-text);
    }

    &__info-text {
        margin-right: 8px;
        color: var(--primary-text);
    }

    &__info-count {
        font-weight: bold;
    }

    &__info-pane {
        background-color: var(--light-background);
    }
}
