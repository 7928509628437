@import './common/common';
@import './common/link';
@import './common/icon';
@import './common/progress';
@import './chartkit';
@import './DataTable';
@import './common/Breadcrumbs';
@import '../fonts/manrope.scss';
@import '../fonts/roboto-mono.scss';

.data-table {
    &__table {
        min-width: 100%;
    }

    &__td,
    &__th {
        max-width: 600px;
    }

    &__head-cell {
        font-weight: bold;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
