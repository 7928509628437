@import '../../styles/common';

.cluster-page {
    &__panes-wrapper {
        margin-top: var(--app-header-height);
        overflow: visible;

        & .flex-split__pane {
            overflow: visible;
        }
    }

    &__panes-wrapper_with-pane {
        overflow: auto;
        max-width: calc(100vw - 56px);

        & .flex-split__pane {
            overflow: auto;
        }
    }

    &__content-pane {
        width: 100%;
        max-width: 100vw;
        display: flex;
        flex-direction: column;

        &_split_yes {
            overflow: auto;
            height: calc(100vh - var(--app-header-height));
        }
    }

    &__warning-pane {
        text-align: center;
        background-color: var(--alert-background);
        padding: 15px;
        margin: 5px;
        border-radius: 5px;
    }

    &__error {
        margin: 0 20px;
    }
}
