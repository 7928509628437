.node-card {
    $meta-table: '.meta-table-item';

    height: calc(100vh - 50px);
    width: 100%;
    padding: 0 20px;

    #{$meta-table} {
        margin-bottom: 0;
        margin-right: 0;

        &__key {
            width: 120px;
        }

        &__value {
            min-width: 200px;
        }
    }

    .collapsible-section:last-child {
        padding-bottom: 20px;
    }

    &:focus {
        outline: none;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        border-bottom: 1px solid var(--dark-divider);
    }

    &__node {
        font-weight: normal;
        font-size: 18px;
        margin: 18px 0;
    }

    &__location {
        border-bottom: 1px solid var(--dark-divider);
        padding-bottom: 20px;
        margin-bottom: 20px;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__default {
        #{$meta-table}__value {
            white-space: normal;
        }
    }

    &__resources,
    &__cpu {
        #{$meta-table}__value {
            width: 200px;
        }
    }
}
