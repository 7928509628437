.no-content {
    &_padding_large {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &_padding_regular {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__image {
        width: 160px;
        height: 160px;
    }

    &__text {
        padding-left: 20px;
        max-width: 250px;
    }

    &__warning {
        font-size: 15px;
        line-height: 20px;
    }
}
