.multimeter {
    &__barchart {
        display: inline-block;
    }

    &__barchart-bar {
        display: inline-block;
        background-color: var(--g-color-text-info);
    }
}
