$groupsCellWidth: 250px;

.users-page-table {
    max-width: 94vw;

    .data-table__table {
        .data-table__th, .data-table__td {
            border: 0;
        }

        .data-table__head-row, .data-table__row {
            border-bottom: 1px solid var(--light-divider);
        }
    }

    &__header-cell {
        &_sortable {
            cursor: pointer;
        }
    }

    &__td_col_member_of,
    &__td_col_transitivegroups {
        min-width: $groupsCellWidth !important;
        max-width: $groupsCellWidth !important;
    }

    &__content {
        padding: 5px 10px;
        height: 60px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;

        &_col_name {
            min-width: 180px;
            max-width: 180px;
        }

        &_col_banned {
            justify-content: center;
        }

        &_col_request_queue_size_limit,
        &_col_read_request_rate_limit,
        &_col_write_request_rate_limit {
            justify-content: flex-end;
            max-width: 80px;
            min-width: 80px;
        }

        &_col_member_of,
        &_col_transitivegroups {
            max-width: $groupsCellWidth;
            min-width: $groupsCellWidth;
        }

        &_col_actions {
            width: 110px;
            justify-content: space-between;
        }
    }

    &__banned {
        color: #ff9a00;
        background-color: rgba(255, 154, 0, 0.1);
        border-radius: 4px;
        padding: 4px 8px;
    }

    &__idm {
        text-align: center;
        flex-grow: 1;
    }

    &__groups {
        width: 100%;
        height: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }

    &__attrs-action,
    &__edit-action {
        color: var(--secondary-text) !important;
    }
}
