@import '../../containers/ClusterPageHeader/ClusterColor.scss';

.cluster-icon {
    &__wrapper {
        display: flex;
        width: 33px;
        height: 33px;
        overflow: hidden;
        position: relative;
        border-radius: 4px;
        @include cluster-styled;

        img {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
    }

    &__placeholder {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        font-style: normal;
        font-weight: 700;

        font-size: 17px;
        line-height: 24px;
        color: #ffffff;
        opacity: 0.85;
    }
}
