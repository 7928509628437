.pages-settings-editor {
    width: 220px;
    display: flex;
    flex-direction: column;

    &__top {
        display: flex;
        align-items: center;
        padding: 18px 11px 20px 20px;
    }

    &__top-title {
        flex-grow: 1;
        font-size: var(--g-text-body-2-font-size);
        line-height: var(--g-text-body-2-line-height);
        font-weight: var(--yt-font-weight-bold);
    }

    &__top-btn {
        border-radius: 4px;
        svg {
            color: var(--g-color-text-secondary);
        }

        &_active {
            background-color: var(--g-color-base-selection);
            svg {
                color: var(--g-color-base-brand);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__service-item {
        flex-grow: 1;
        height: 100%;
    }

    &__service-item-link {
        &_current {
            .nv-composite-bar__menu-icon {
                color: var(--g-color-base-brand);
            }
        }
    }
}
