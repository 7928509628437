@import 'mixins';

// Source https://codepen.io/martinwolf/pen/qlFdp

.elements-multiline-ellipsis {
    @include ellipsis();
    display: block;

    line-height: 1.4em;
    max-width: 100%;
    height: 5.6em;
    margin: 0 auto;

    white-space: normal;

    -webkit-line-clamp: 4;
    /* stylelint-disable */
    box-orient: vertical;
    /* stylelint-enable */
}

.elements-ellipsis {
    @include ellipsis();

    display: inline-block;

    max-width: 100%;

    vertical-align: bottom;
}
