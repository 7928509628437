@import '../globals';

.attributes-modal {
    width: 720px;

    .unipika {
        padding: 0;
        border: 0;
    }
}
