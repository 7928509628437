.navigation-table {
    $self: &;
    padding-top: 10px;

    .collapsible-section {
        margin-top: 0;
    }

    &__fullscreen:fullscreen {
        background-color: var(--main-background);
    }
}
