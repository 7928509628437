.system-nodes {
    .elements-heading {
        margin-bottom: 0;
    }

    &__group-summary {
        padding-left: 4px;
        padding-bottom: 20px;
    }

    &__node-type {
        padding-right: 10px;
    }
}
