.bundle-meta-resource-progress {
    display: flex;
    align-items: center;

    &__info {
        margin-left: 8px;

        color: var(--g-color-text-secondary);
    }

    & .g-progress {
        width: 180px;
    }

    &__tooltip-list {
        margin: 0;
        padding: 0;

        list-style: none;
    }
}
