.job-breadcrumbs {
    .du-breadcrumbs,
    .textinput {
        max-width: 600px;
    }

    &__icon {
        color: var(--secondary-text);

        .icon {
            margin-top: 2px;
            margin-left: 3px;
        }
    }

    &__input {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .yc-field-wrapper {
            width: 300px;
        }
    }

    &__id {
        display: flex;
        align-items: center;
    }
}
