.query-result-visualization {
    height: 100%;
    display: flex;
    border-bottom: 1px solid var(--g-color-line-generic);

    &__loader-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__controls-wrapper {
        border-right: 1px solid var(--g-color-line-generic);
        padding-right: 8px;
    }

    &__chartkit-wrapper {
        min-height: 200px;
        width: 100%;
    }

    &__group-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
            padding-top: 0px;
        }

        padding-top: 8px;
    }
}
