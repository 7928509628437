.yt-role-group {
    &__container {
        padding-bottom: 20px;
    }

    display: inline-block;
    min-width: 300px;
    max-width: 450px;

    flex-grow: 1;

    padding: 10px 16px;
    border: 1px solid var(--dark-divider);

    &__progress {
        display: flex;
        overflow: hidden;
    }

    &__progress-text {
        flex-grow: 1;
    }

    &__progress-number {
        line-height: 36px;
    }

    &__counters {
        display: grid;
        grid-template-columns: auto 1fr auto 1fr auto 1fr auto;
        row-gap: 4px;
    }

    &__status {
        display: inline-block;
    }

    &__status-color {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        margin-right: 4px;

        background-color: var(--g-color-base-generic-medium);

        &_theme_online {
            background-color: var(--success-color);
        }

        &_theme_offline {
            background-color: var(--danger-color);
        }

        &_theme_alert {
            background-color: var(--warning-color);
        }

        &_theme_other {
            background-color: var(--info-color);
        }

        &_empty {
            background-color: var(--light-divider);
        }
    }

    &__status-label {
        text-transform: uppercase;
        line-height: 14px;
    }

    &__status-count {
        display: flex;
        align-items: center;
    }

    &__status-count-number {
        line-height: 14px;
    }
}
