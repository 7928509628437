@import '../globals';
@import '../mixins';

.unipika {
    font-family: var(--g-font-family-monospace);

    &-wrapper & {
        margin: 0;
        padding: 0;
        border: 0;
    }

    &-wrapper_inline_yes & {
        @include ellipsis();
        display: inline-block;
        vertical-align: bottom;
        max-width: 100%;
    }

    .tag_image {
        max-width: 300px;
    }
}

.g-link_view_primary .unipika-wrapper .unipika,
.g-link_view_normal .unipika-wrapper .unipika,
.g-link_view_primary.unipika-wrapper .unipika,
.g-link_view_normal.unipika-wrapper .unipika {
    .string {
        color: var(--primary-link);

        &:hover {
            color: var(--hover-link);
        }
    }
}
