.yt-markdown {
    a {
        color: var(--external-link) !important;
        text-decoration: none;

        &:hover {
            color: var(--hover-link) !important;
        }
    }
}
