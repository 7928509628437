.navigation-file {
    .elements-code:not(:last-child) {
        margin-bottom: 20px;
    }

    &_loading {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__actions {
        margin-bottom: 20px;
    }

    &__download {
        margin-right: 20px;
    }
}
