.navigation-document {
    padding-top: 10px;

    &_loading {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
