.account-create-dialog {
    &__name {
        *::placeholder {
            color: var(--g-color-text-hint) !important;
        }

        .textinput__box {
            border-width: 2px !important;
        }
    }
}
