.navigation-attributes-editor {
    .df-dialog_with_vertical_tabs {
        min-height: 415px;
    }

    &_single-mode {
        .df-dialog_with_vertical_tabs {
            min-height: 570px;
        }
    }

    &__run-notice {
        color: var(--warning-text);
    }

    .df-dialog__field-group_type_annotation {
        .df-dialog__field-wrapper_type_annotation {
            padding-top: 0;
        }

        .textarea__control {
            //height: 250px;
        }
    }

    &__full-height {
        height: 473px;
    }

    &__path-list {
        max-height: 50vh;
        overflow: auto;
    }
}
