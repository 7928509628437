.yc-datepicker-month {
    display: grid;
    grid-template-columns: repeat(7, 36px);
    grid-auto-rows: 36px;
    grid-gap: 4px;
    justify-content: center;
    margin-bottom: 5px;

    &__day {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;

        .yc-datepicker_desktop &:hover {
            background-color: var(--g-color-base-simple-hover);
        }

        &_weekend {
            color: var(--g-color-text-danger);
        }

        &_today {
            color: var(--g-color-text-info);
        }

        &_selected {
            border-radius: 0;
            background-color: var(--g-color-base-selection);

            .yc-datepicker_desktop &:hover {
                background-color: var(--g-color-base-selection);
            }
        }

        &_right-edge {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &_selected:not(&_left-edge)::before {
            content: '';
            position: absolute;
            top: 0;
            left: -4px;
            display: block;
            height: 36px;
            width: 4px;
            background-color: var(--g-color-base-selection);
        }

        &_left-edge,
        &:not(&_selected) + &_selected {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &::before {
                height: 0;
            }
        }

        &_selected + &:not(&_selected):not(&_left-edge) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -8px;
                display: block;
                height: 36px;
                width: 4px;
                background-color: var(--g-color-base-float);
                pointer-events: none;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: -8px;
                display: block;
                height: 36px;
                width: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                background-color: var(--g-color-base-selection);
                pointer-events: none;
            }
        }

        &_disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &__title {
        grid-area: title;
        align-self: center;
        font-size: 18px;
        line-height: 20px;
        color: var(--g-color-text-secondary);

        &_disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}
