.yt-data-table {
    &__content {
        display: flex;
        align-items: center;
        padding: 1ex;

        width: 100%;
        height: 100%;
        min-height: 30px;

        &_align_center {
            justify-content: space-around;
        }

        &_align_right {
            justify-content: flex-end;
        }
    }

    @keyframes shimmer {
        0% {
            opacity: 0.9;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.9;
        }
    }

    &__no-data-placeholder {
        background-color: var(--dark-divider);
        animation: shimmer 2s ease-in-out infinite;
        border-radius: 3px;
        height: 10px;
        width: 50%;
    }

    .data-table_theme_yt,
    .data-table_theme_yt-borderless {
        .data-table__td,
        .data-table__th {
            padding-top: 12px;
            padding-bottom: 9px;
        }

        .data-table__row,
        .data-table__head-row {
            border-bottom: 1px solid var(--g-color-line-generic);
        }

        .data-table__th {
            background-color: var(--main-background);
        }
    }

    .data-table_theme_yt-borderless {
        .data-table__row:hover {
            background-color: var(--hover-background);
        }

        .data-table__row {
            border-bottom: none;
        }

        .data-table__head-row {
            border-bottom: 1px solid var(--dark-divider);
        }
    }

    .data-table__box_sticky-head_moving .data-table__th {
        // to collapse thead-element of
        padding-bottom: unset;
        padding-top: unset;
    }

    &_right-gap {
        // the block is required to make right-gap for tables wider than the screen width
        .data-table__th:last-child::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 0;
            margin-right: -10px;
        }
    }
}
