.elements-histogram {
    &__switcher {
        width: 200px;
        margin-bottom: 20px;
    }

    &__content {
        position: relative;
    }

    &__container {
        height: 300px;
        width: 100%;
    }

    &__message {
        margin: 0;
        text-align: center;

        &-container {
            font-size: 18px;

            display: none;

            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            padding: 10px 30px;
            margin-top: -40px;

            color: var(--secondary-text);
            border-radius: 3px;
            border: 2px solid var(--info-color);
        }
    }

    &__legend {
        text-align: center;

        &-container {
            // TODO
        }

        &-label {
            white-space: nowrap;

            margin-right: 10px;
        }
        &-value {
            font-weight: bold;
        }
    }
}
