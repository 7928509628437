.bundle-editor {
    &__dialog {
        .df-dialog_with_vertical_tabs {
            height: 60vh;
        }

        .df-dialog__tabs-content {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .g-dialog-divider {
            margin: 0;
        }

        .df-dialog__error-message {
            white-space: pre;
        }

        .df-dialog__field-group_type_bundle-title {
            .df-dialog__label-text {
                font-weight: 700;
            }
        }
    }
}
