.g-breadcrumbs {
    .g-breadcrumbs__switcher,
    .g-breadcrumbs__item {
        min-width: 30px;
        border-radius: 4px;
        padding: 0 4px;

        &:last-child {
            padding-right: 8px;
        }
    }

    .g-breadcrumbs__switcher-wrapper {
        .g-breadcrumbs__switcher {
            min-width: unset;
        }
    }

    .g-breadcrumbs__item_current {
        flex-shrink: 0;
    }
}
