.yt-dialog {
    &__error {
        margin: 0 -32px;
        padding: 20px 32px;
        overflow: auto;
    }

    &__as-block {
        display: inline-block;
        margin-left: -32px;
        margin-top: -10px;

        &_has-header {
            margin-top: -20px;
        }

        .df-dialog__body {
            min-width: 820px;
        }
    }
}

.df-dialog_with_vertical_tabs {
    .yt-dialog__error {
        margin-left: -14px;
    }
}

.df-dialog-tab__name-text {
    line-height: 1.43;
}

.df-dialog__field-wrapper_type_datepicker {
    .yc-datepicker__control {
        width: 100%;
    }
}

.df-dialog__field-warning {
    display: flex;
}

.g-dialog-divider {
    margin: 0;
}
