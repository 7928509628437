@import '../../styles/mixins';

.collapsible-list {
    &__list {
        &_use-flex {
            display: flex;
            flex-wrap: wrap;
        }

        @include reset-ul();

        li {
            @include reset-li();
        }
    }
}
