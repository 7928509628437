.expand-icon {
    padding: 0 1ex;
    visibility: hidden;

    &_visible {
        visibility: unset;
    }

    &_clickable {
        cursor: pointer;
    }
}
