body .yt-select {
    font-size: 13px;

    .g-button__text {
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
    }

    &__item-icon {
        padding-right: 1ex;
    }

    &__item-text {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__item-count {
        padding-left: 1ex;
    }

    &__empty {
        font-style: italic;
    }

    &__control-value {
        display: flex;
        align-items: center;
        flex-shrink: 1;
        flex-grow: 1;
        overflow: hidden;
    }

    &__control-label {
        padding-right: 0.5ex;
    }

    &__chevron {
        margin-left: 4px;
        color: var(--secondary-text);
    }

    &__filter {
        display: flex;
        align-items: center;
    }

    &__filter-btn {
        padding-left: 4px;
    }
}

body .yc-select_size_m,
body .yc-select-list_size_m {
    font-size: 13px;
}
