.map-nodes-table {
    &__name-cell {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__name-cell-text {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__name-cell-tags {
        flex-shrink: 0;
    }

    &__name {
        .unipika {
            display: inline;
            white-space: nowrap;

            font-family: var(--g-font-family-sans);

            .quote {
                display: none;
            }

            .escape {
                word-spacing: 5px;
                white-space: pre;
                background-color: var(--error-background);
            }

            & .string {
                position: relative;
                color: var(--primary-text);
                vertical-align: bottom;
            }

            & .string.binary {
                padding-right: 60px;
            }

            & .string.incomplete {
                padding-right: 80px;
            }

            & .string.binary.incomplete {
                padding-right: 140px;
            }

            & .string::after {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
