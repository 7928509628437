.editable-as-text {
    display: flex;
    align-items: center;

    &__control {
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
        &_type {
            &_edit {
                margin-left: 8px;
            }
        }
    }
}
