.yc-datepicker-quarters {
    display: grid;
    grid-template-columns: 51px repeat(4, 53px);
    grid-template-rows: repeat(1, 40px);
    grid-gap: 4px;
    justify-content: center;
    margin-bottom: 4px;

    &__quarter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;

        .yc-datepicker_desktop &:hover {
            background-color: var(--g-color-base-simple-hover);
        }

        &_today {
            color: var(--g-color-text-info);
        }

        &_selected {
            border-radius: 0;
            background-color: var(--g-color-base-selection);

            .yc-datepicker_desktop &:hover {
                background-color: var(--g-color-base-selection);
            }
        }

        &_left-edge {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &_right-edge {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &_selected:not(&_left-edge)::before {
            content: '';
            position: absolute;
            top: 0;
            left: -4px;
            display: block;
            height: 40px;
            width: 4px;
            background-color: var(--g-color-base-selection);
        }

        &_disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &__title {
        align-self: center;
        font-size: 18px;
        line-height: 20px;
        color: var(--g-color-text-secondary);

        &_disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}
