.bundle-configuration-meta {
    flex-shrink: 0;

    .meta-table__group {
        grid-gap: 6px 27px;
    }

    &__percentage {
        margin-left: 6px;

        color: var(--g-color-text-secondary);
    }

    &__health {
        &_value {
            &_changing {
                color: var(--info-color);
            }
        }
    }

    &__tooltip-list {
        margin: 0;
        padding: 0;

        &:not(&_alerts) {
            list-style: none;
        }

        &_alerts {
            padding-left: var(--g-popover-padding);
        }
    }

    &__state-with-icon {
        display: flex;
        align-items: center;
        color: var(--g-color-text-secondary);
    }
}
