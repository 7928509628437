@import '../../../styles/fonts.scss';

.top-row-section {
    display: flex;
    align-items: center;

    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    color: var(--secondary-text);
    padding-left: 16px;
    @include font-body2;

    &__name {
        padding-right: 16px;
        font-weight: 600;
        white-space: nowrap;
        flex-shrink: 0.5;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 6ex;

        color: var(--primary-text);
        @include font-body3;
        font-size: 15px;
    }

    &__spacer {
        height: 24px;
        border-left: 1px solid var(--dark-divider);
        padding-right: 16px;
    }
}
