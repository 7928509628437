.chartkit {
    --ck-loader-active-color: var(--g-color-base-brand);
    --ck-loader-off-color: var(--yc-color-celestial-thunder);
}

.theme-dark {
    .chartkit-loader_veiled {
        background-color: var(--main-background);
    }
}

.highcharts-label text {
    fill: var(--primary-text) !important;
}
