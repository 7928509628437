.timepicker {
    .icon_awesome {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__control {
        width: 80px;
    }
}
