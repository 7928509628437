.cluster-select-control {
    &__warning {
        color: var(--warning-text);
    }

    &__name {
        color: var(--secondary-text);
        text-transform: capitalize;
    }
}
