$pin-color: var(--g-color-base-brand);
$pin-focus-color: var(--g-color-base-selection);
$pin-disabled-color: var(--g-color-sfx-veil);

.yc-range-input-picker {
    $block: &;
    outline: none;
    position: relative;

    &__range-info-items-list {
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__range-info-item-container {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
    }

    &__range-info-item {
        cursor: pointer;
        color: var(--g-color-text-secondary);
        font-size: var(--yc-range-input-picker-font-size);
        line-height: var(--yc-range-input-picker-line-height);
    }

    .rc-slider {
        position: relative;
        bottom: 6px;
        z-index: 3;
        height: 14px;
        padding: 5px 0;
        width: 100%;
        border-radius: 6px;
        touch-action: none;
        box-sizing: border-box;

        &-rail {
            position: absolute;
            width: 100%;
            height: 4px;
            border-radius: 6px;
        }

        &-track {
            position: absolute;
            left: 0;
            top: 2px;
            height: 4px;
            border-radius: 6px;
            background-color: $pin-color;
        }

        &-handle {
            position: absolute;
            top: -2px;
            width: 12px;
            height: 12px;
            cursor: pointer;
            border-radius: 50%;
            background-color: $pin-color;
            touch-action: pan-x;

            &:active {
                box-shadow: 0 0 5px $pin-color;
                cursor: grabbing;
            }

            &:focus {
                box-shadow: 0 0 0 5px $pin-focus-color;
                outline: none;
            }
        }

        &-disabled .rc-slider-handle {
            box-shadow: none;
            background-color: var(--g-color-base-float);
            cursor: not-allowed;
        }

        &-disabled .rc-slider-track {
            background-color: $pin-disabled-color;
        }

        &-step {
            position: absolute;
            transform: translateY(-50%);
            width: 100%;
            height: 4px;
            background: transparent;
        }

        &-mark {
            position: absolute;
            top: 13px;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;

            &-text {
                //stylelint-disable-next-line
                transform: translateX(0) !important;
                cursor: pointer;
                color: var(--g-color-text-secondary);
                font-size: var(--yc-range-input-picker-font-size);
                line-height: var(--yc-range-input-picker-line-height);
            }
        }
    }

    &_size_s {
        --yc-range-input-picker-font-size: var(--g-text-body-short-font-size);
        --yc-range-input-picker-line-height: var(--g-text-body-short-line-height);

        #{$block}__range-info-items-list {
            top: 31px;
        }

        .rc-slider {
            &-handle {
                top: 0;
                width: 9px;
                height: 9px;
            }

            &-track {
                top: 4px;
                height: 2px;
            }

            &-step {
                height: 2px;
            }
        }
    }

    &_size_m {
        --yc-range-input-picker-font-size: var(--g-text-body-short-font-size);
        --yc-range-input-picker-line-height: var(--g-text-body-short-line-height);

        #{$block}__range-info-items-list {
            top: 35px;
        }

        .rc-slider {
            &-handle {
                top: -1px;
                width: 11px;
                height: 11px;
            }

            &-track {
                top: 3px;
                height: 3px;
            }

            &-step {
                height: 3px;
            }
        }
    }

    &_size_l {
        --yc-range-input-picker-font-size: var(--g-text-body-short-font-size);
        --yc-range-input-picker-line-height: var(--g-text-body-short-line-height);

        #{$block}__range-info-items-list {
            top: 43px;
        }
    }

    &_size_xl {
        --yc-range-input-picker-font-size: var(--g-text-body-2-font-size);
        --yc-range-input-picker-line-height: var(--g-text-body-2-line-height);

        #{$block}__range-info-items-list {
            top: 51px;
        }
    }
}
