.external-description {
    display: flex;
    align-items: center;

    &__meta {
        margin-top: 10px;
    }

    &__icon {
        color: var(--g-color-text-warning);
        margin-right: 1em;
    }

    &__preview {
        flex: 1;
    }
}
