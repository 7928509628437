.bundle-params-list {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    gap: 15px;

    color: var(--g-color-text-secondary);

    &_size_s {
        font-size: var(--g-text-body-short-font-size);
        line-height: var(--g-text-body-short-line-height);
    }

    &_size_m {
        font-size: var(--g-text-body-2-font-size);
        line-height: var(--g-text-body-2-line-height);
    }

    &__value {
        margin-left: 3px;
        color: var(--g-color-text-primary);

        &_type_positive {
            color: var(--g-color-text-positive);
        }

        &_type_negative {
            color: var(--g-color-text-danger);
        }
    }
}
