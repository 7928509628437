@import './login-page-theme-light.scss';
@import './login-page-theme-dark.scss';

.g-root_theme_light,
.g-root_theme_light-hc {
    @include yt-login-page-light;
}

.g-root_theme_dark,
.g-root_theme_dark-hc {
    @include yt-login-page-dark;
}

@mixin login-page-font-settings {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
}

.login-page {
    @include login-page-font-settings;

    position: relative;
    display: flex;
    flex-direction: column;

    min-width: 320px;
    min-height: 100vh;
    justify-content: center;

    background-color: var(--login-background);
    background-image: var(--login-background-image);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0;
    background-size: auto 100%;

    &__logo {
        position: absolute;
        top: 40px;
        left: 40px;
        width: 160.32px;
        height: 33.06px;
    }

    &__content {
        max-width: 300px;
        margin-left: 150px;

        @media (min-width: 1200px) {
            margin-left: 150px;
        }

        @media (min-width: 1400px) {
            margin-left: 200px;
        }

        @media (min-width: 1600px) {
            margin-left: 250px;
        }

        @media (min-width: 1920px) {
            margin-left: 330px;
        }
    }

    &__title {
        font-size: 30px;
        line-height: 36px;
        margin: 0 0 8px;
        font-weight: 600;
        white-space: nowrap;
    }

    &__text {
        font-size: 13px;
        line-height: 18px;
        margin: 0 0 24px;
    }

    &__error {
        margin: 12px 0;
    }

    &__field {
        margin-bottom: 20px;
        --g-text-input-border-radius: 10px;

        .g-text-input__control {
            @include login-page-font-settings;
            height: 40px;
            padding: 12px 10px;
            font-size: 13px;
            line-height: 16px;
            background: none;
        }

        &_password {
            .g-text-input__control:not(:placeholder-shown) {
                letter-spacing: 2px;
            }
        }
    }

    &__button {
        @include login-page-font-settings;
        height: 38px;
        font-size: 15px;

        &_solid {
            --g-color-base-brand: var(--login-button-solid);
            --g-color-base-brand-hover: var(--login-button-solid-hover);
        }

        &_link {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_submit {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .g-button_loading {
        --g-button-loading-color-1: var(--login-button-loading-color-1);
        --g-button-loading-color-2: var(--login-button-loading-color-2);
    }

    .g-button_view_outlined::before {
        background-color: var(--login-background);
    }

    .g-button:hover::before {
        background-color: var(--login-button-solid-hover);
    }

    &__navigate {
        padding-top: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__navigate-link {
        margin: 0;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: left;
        color: var(--g-color-text-secondary);
        border-bottom: 1px dashed;
        border-color: var(--g-color-line-generic-accent);

        &:hover {
            color: var(--g-color-text-primary);
            border-color: var(--g-color-line-generic-accent-hover);
        }
    }
}
