.yt-subject-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    &__name {
        flex-grow: 1;

        color: var(--primary-text);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            color: var(--danger-color);
        }
    }

    &__icon {
        color: var(--g-color-text-secondary);
        padding-right: 8px;
    }

    &__icon-svg {
        margin-top: 1px;
        margin-bottom: -4px;
    }
}
