.g-root {
    .g-link {
        &_view_normal {
            color: var(--external-link);
        }

        &_view_black {
            color: var(--primary-link);
        }

        &_view_ghost {
            color: var(--secondary-link);
        }

        &_view_secondary {
            color: var(--secondary-text);
        }

        &:hover {
            color: var(--hover-link);
        }
    }

    .g-link_view_normal.g-link_disabled_yes,
    .g-link_view_primary.g-link_disabled_yes,
    .g-link_view_ghost.g-link_disabled_yes {
        color: var(--disabled-link);
    }
}
