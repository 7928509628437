.info-block {
    --external-link: var(--g-color-text-link);

    display: flex;
    padding: 12px 16px;
    background-color: var(--g-color-base-info-light);
    border-radius: 5px;

    .g-icon {
        color: #027bf3;
    }

    &__icon {
        flex-shrink: 0;
        margin-right: 8px;
    }

    &__body {
        font-size: var(--g-text-body-short-font-size);
        line-height: var(--g-text-body-short-line-height);
        color: var(--g-color-text-secondary);
    }
}
