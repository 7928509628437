.chart-validation {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__alert {
        padding: 12px;
        font-size: 10px;
    }

    &__icon {
        &_invalid {
            color: var(--g-color-line-warning);
        }
    }
}
