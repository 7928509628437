.bundle-meta-table {
    .meta-table__group {
        grid-gap: 6px 27px;
    }

    .g-progress {
        width: 200px;
    }

    &__container {
        display: flex;
        align-items: flex-start;
    }
}
