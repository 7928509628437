@import '../mixins';

$animation-duration: 0.2s;

.elements-modal-backdrop {
    position: fixed;
    z-index: var(--dialog-backdrop-z-index);
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: -9999px 0 0 -9999px;

    background-color: var(--opacity-background);
    visibility: hidden;
    animation-duration: $animation-duration;
    animation-timing-function: ease-in-out;

    &_state_visible {
        margin: 0;

        visibility: visible;
        animation-name: elements-modal-backdrop-show;
    }

    &_state_hidden {
        animation-name: elements-modal-backdrop-hide;
    }
}

.elements-modal {
    display: flex;

    position: fixed;
    z-index: var(--dialog-z-index);
    inset: 0;

    &__warning {
        & .elements-message {
            margin: 0;
        }
    }

    &__inner {
        display: flex;

        max-width: 100%;
        margin: auto;
        padding: 30px 15px;

        animation-duration: $animation-duration;
        animation-timing-function: ease-in-out;
    }

    &__content {
        display: flex;
        flex-direction: column;

        width: 600px;
        max-height: 70vh;
        margin: auto;

        background-color: var(--main-background);
        box-shadow: 0 2px 10px var(--opacity-background);
        border-radius: 3px;
    }

    &__header,
    &__footer {
        flex-shrink: 0;
    }

    &__header {
        @include ellipsis();

        font-size: 16px;
        font-weight: var(--yt-font-weight-bold);
        line-height: 18px;

        position: relative;

        padding: 15px 40px;
        height: 48px;

        text-align: center;

        border-bottom: 1px solid var(--light-divider);
    }

    &__close {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &__body {
        flex-grow: 1;

        padding: 15px 30px;

        overflow-y: auto;
    }

    &__footer {
        display: flex;

        padding: 15px 30px;
        height: 59px;

        border-top: 1px solid var(--light-divider);

        &-left,
        &-right {
            display: flex;
            flex-shrink: 0;
            align-items: center;
        }

        &-right {
            margin-left: auto;
        }

        &-right &-button {
            margin-left: 20px;
        }

        &-left &-button {
            margin-right: 20px;
        }

        & &-button {
            vertical-align: bottom;
            min-width: 85px;
        }
    }

    &:not(:last-child) {
        opacity: 0;
    }

    &_state_visible {
        .elements-modal__inner {
            animation-name: elements-modal-show;
        }
    }

    &_state_hidden {
        .elements-modal__inner {
            animation-name: elements-modal-hide;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes elements-modal-backdrop-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes elements-modal-backdrop-hide {
    0% {
        visibility: visible;
        margin: 0;
        opacity: 1;
    }

    99% {
        margin: 0;
    }

    100% {
        visibility: hidden;
        margin: -9999px 0 0 -9999px;
        opacity: 0;
    }
}

@keyframes elements-modal-show {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes elements-modal-hide {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}
