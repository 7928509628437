@import '../../styles/mixins.scss';
@import './Toolbar/Toolbar.scss';

$stickySpacerHeight: 6px;

.with-sticky-toolbar {
    $height: $TOOLBAR_COMPONENT_HEIGHT;

    &__toolbar {
        height: $height;

        &_padding_skip-vertical {
            margin-top: -10px;
            margin-bottom: 10px;
        }

        &_padding_skip-horizontal {
            padding-left: unset;
            padding-right: unset;
        }

        $toolbar: &;

        &_sticky {
            @include sticky-style();
            padding: 0px 20px;
            z-index: var(--fixed-toolbar-z-index);

            &#{$toolbar}_padding_skip-vertical {
                margin-top: unset;
                margin-bottom: unset;
            }

            &#{$toolbar}_padding_skip-horizontal {
                padding-left: unset;
                padding-right: unset;
            }
        }

        &_x2 {
            height: 2 * $height;
        }

        &-spacer {
            display: none;

            &_sticky {
                display: unset;
                position: fixed;
                height: $stickySpacerHeight;
                width: 100%;
                background-color: var(--main-background);
                z-index: var(--fixed-toolbar-spacer-z-index);
                top: calc(var(--app-header-height) + #{$height});
            }

            &_x2 {
                top: calc(var(--app-header-height) + #{2 * $height});
            }
        }
    }

    &_bottom-margin_regular {
        margin-bottom: 20px;
    }
}
