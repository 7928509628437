@import './fonts';
@import './common';

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin text-label() {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

// reset

@mixin reset-ul() {
    margin: 0;
    padding: 0;
}

@mixin reset-li() {
    list-style-type: none;
}

// font

@mixin yt-fonts() {
    font-family: var(--g-font-family-sans);
    font-size: var(--g-font-size);
    line-height: var(--g-line-height);
}

// heading

@mixin heading-size($key) {
    $sizes: (
        s: (
            16px,
            20px,
            $offset-heading-small,
        ),
        xs: (
            18px,
            20px,
            $offset-heading-small-x,
        ),
        m: (
            20px,
            24px,
            $offset-heading-medium,
        ),
        l: (
            24px,
            28px,
            $offset-heading-large,
        ),
        xl: (
            24px,
            28px,
            $offset-heading-large,
        ),
    );
    $size: map-get($sizes, $key);

    font-size: nth($size, 1);
    line-height: nth($size, 2);
    margin: 0;
    margin-bottom: nth($size, 3);
}

$stickyVerticalPadding: 10px;

@mixin sticky-style() {
    position: fixed;
    top: var(--app-header-height);
    left: var(--gn-aside-header-size);
    right: 0;

    padding: $stickyVerticalPadding 20px;

    box-shadow: 0 1px 10px var(--opacity-background);
    background-color: var(--main-background);
    border-bottom: 1px solid var(--dark-divider);
    z-index: var(--fixed-toolbar-z-index);
}
