.elements-list {
    &_type_unstyled {
        list-style-type: none;

        margin: 0;
        padding: 0;
    }

    &_inline_yes {
        display: flex;
        flex-wrap: wrap;

        & > li {
            margin-left: 5px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
