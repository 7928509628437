.filter-with-regexp {
    &__controls {
        display: flex;
        flex-wrap: nowrap;
    }

    &__filter {
        flex-grow: 1;
    }

    &__error {
        color: var(--danger-text);
    }
}
