@import '../../../styles/common.scss';

.navigation-description {
    &__collapsible {
        margin-top: unset;
    }

    &__edit-block {
        height: 300px;
    }
}
