.edit-text-with-preview {
    display: flex;
    flex-direction: column;
    position: relative;

    min-height: 100px;

    &__left {
        display: flex;
    }

    &__monaco {
        flex-grow: 1;
        width: 100%;
    }

    .Resizer {
        width: 10px;
        cursor: col-resize;
    }

    .Pane {
        min-width: 0px;
    }

    &__panel {
        height: 100%;

        &_growable {
            flex-grow: 1;
        }
    }

    &__markdown {
        overflow: auto;
        padding: 0 1ex;
    }
}
