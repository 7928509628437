@use 'sass:math';

@import '../../../../../styles/mixins.scss';

$item-margin: 30px;

.nodes-types {
    min-width: 1100px;

    &__list {
        @include reset-ul();
        padding: 12px 16px;
        background-color: var(--light-background);
        border-radius: 5px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__item {
        @include reset-li();
        margin-right: $item-margin;
        position: relative;

        &:not(:last-child):after {
            content: '•';
            position: absolute;
            right: math.div(-$item-margin, 2);
            top: 50%;
            transform: translate(50%, -50%);
        }
    }

    &__type {
        margin-right: 8px;
    }

    &__count {
        font-weight: var(--yt-font-weight-bold);
    }

    &__actions {
        margin: -10px 20px;
    }
}
