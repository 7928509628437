@font-face {
    font-family: Manrope;
    font-weight: 300;
    src: url('../../assets/fonts/Manrope/otf/Manrope-Light.otf') format('opentype');
}

@font-face {
    font-family: Manrope;
    font-weight: 400;
    src: url('../../assets/fonts/Manrope/otf/Manrope-Regular.otf') format('opentype');
}

@font-face {
    font-family: Manrope;
    font-weight: 600;
    src: url('../../assets/fonts/Manrope/otf/Manrope-SemiBold.otf') format('opentype');
}

@font-face {
    font-family: Manrope;
    font-weight: 700;
    src: url('../../assets/fonts/Manrope/otf/Manrope-Bold.otf') format('opentype');
}
